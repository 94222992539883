"use client";

import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";

import { trackClickHeaderMenu } from "@saas/layout/utils";
import { ChevronDownIcon, SVG } from "@saas/shared/icon";
import { MenuItem, Typography } from "@saas/shared/ui";
import { classNames, useScrollDirection } from "@saas/shared/utils";

type MenuItem = {
  icon?: SVG;
  label: string;
  url: string;
  testid?: string;
};

type MenuHeader = {
  label: string;
  testid?: string;
  url?: string;
  enabled?: boolean;
  child?: MenuItem[];
};

interface LeftNavBarProps {
  menuItems: MenuHeader[];
  whiteMenu?: boolean;
}

export const LeftNavBar = ({ menuItems, whiteMenu }: LeftNavBarProps) => {
  const scrollDirection = useScrollDirection();
  const router = useRouter();
  const currentRoute = router.pathname;

  const [showDropdown, setShowDropdown] = useState<number>(0);
  /**
   * open dropdown menu based on showDropdown state
   */
  const showDropdownMenu = useCallback(
    (menu: MenuItem[], index: number) => {
      return showDropdown === index + 1
        ? menu.map((menuItem, index) => {
            const Icon = menuItem.icon;

            return (
              <div className={"group flex w-full justify-start"} key={index}>
                <Link
                  href={menuItem.url}
                  className={
                    index + 1 === menu.length
                      ? "flex min-h-[2.5rem] items-center gap-2"
                      : "flex min-h-[2.5rem] items-center gap-2 rounded"
                  }
                  data-testid={menuItem.testid}
                  onClick={() => trackClickHeaderMenu(menuItem.label)}
                >
                  {Icon ? (
                    <Icon
                      className={
                        "text-le group-hover:text-blue-B700 inline-flex flex-shrink-0"
                      }
                    />
                  ) : null}
                  <Typography
                    type={"body-b2"}
                    className={"group-hover:text-blue-B700 text-sm"}
                  >
                    {menuItem.label}
                  </Typography>
                </Link>
              </div>
            );
          })
        : null;
    },
    [showDropdown]
  );

  /**
   * render link based on menu (reload for home)
   */
  const linkHeader = useCallback(
    (menuItem: MenuHeader) => {
      return menuItem.label === "Home" ? (
        <a href={menuItem.url} data-testid={menuItem.testid}>
          <div>
            <Typography
              type={"button-large"}
              className={classNames(
                "uppercase",
                whiteMenu ? "text-white" : "text-blue-B700",
                scrollDirection ? "text-white" : "text-button"
              )}
            >
              {menuItem.label}
            </Typography>
          </div>
        </a>
      ) : (
        menuItem.url && (
          <Link
            href={menuItem.url}
            data-testid={menuItem.testid}
            onClick={() => {
              trackClickHeaderMenu(menuItem.label);
            }}
          >
            <Typography
              type={"button-large"}
              className={classNames(
                "text-sm font-bold",
                whiteMenu ? "text-white" : "text-blue-B700",
                scrollDirection ? "text-white" : "!text-button"
              )}
            >
              {menuItem.label}
            </Typography>
          </Link>
        )
      );
    },
    [whiteMenu, scrollDirection]
  );

  return (
    <div className={"relative hidden items-center gap-6 lg:flex"}>
      {menuItems.map((menuItem, index) => {
        return menuItem.enabled ? (
          menuItem.url ? (
            <div
              className={classNames(
                "hover:bg-blue-B100 flex cursor-pointer justify-center p-2 uppercase hover:rounded",
                currentRoute === menuItem.url && "bg-blue-B100 rounded"
              )}
              key={index}
            >
              {linkHeader(menuItem)}
            </div>
          ) : (
            menuItem.child && (
              <div
                key={index}
                className={classNames(
                  "hover:bg-blue-B100 relative hidden cursor-pointer items-center rounded p-2 hover:rounded lg:flex"
                )}
                data-testid={menuItem.testid}
                onClick={() => {
                  showDropdown === 0
                    ? setShowDropdown(index + 1)
                    : setShowDropdown(0);
                }}
              >
                <Typography
                  type={"button-large"}
                  className={classNames(
                    "text-sm font-bold uppercase",
                    whiteMenu ? "text-white" : "text-blue-B700",
                    scrollDirection ? "text-white" : "!text-button"
                  )}
                >
                  {menuItem.label}
                </Typography>
                <ChevronDownIcon
                  className={classNames(
                    "transition duration-200",
                    showDropdown === index + 1 ? "rotate-180" : "",
                    whiteMenu ? "text-white" : "text-blue-B700",
                    scrollDirection ? "text-white" : "!text-button"
                  )}
                />
                {showDropdown === index + 1 ? (
                  <button
                    className={
                      "align-center absolute top-8 flex w-56 flex-col gap-2 rounded-lg bg-white px-4 py-4 drop-shadow"
                    }
                  >
                    {showDropdownMenu(menuItem.child, index)}
                  </button>
                ) : null}
              </div>
            )
          )
        ) : null;
      })}
    </div>
  );
};

export default LeftNavBar;
