"use client";

import { ComponentPropsWithoutRef, ReactNode, useState } from "react";

import { env } from "@saas/config/shared";
import { useFlagStatus } from "@saas/flags/feature";
import { SVG } from "@saas/shared/icon";
import { Logo, ScheduleDemoButton, SlideOver } from "@saas/shared/ui";
import { classNames, useScrollDirection } from "@saas/shared/utils";

import { SidebarMenu, SidebarMenuItem } from "..";

export type MenuItem = {
  icon?: SVG;
  label: string;
  url: string;
  testid?: string;
};

export type HeaderMenu = {
  label: string;
  testid?: string;
  url?: string;
  enabled?: boolean;
  child?: MenuItem[];
};

export interface HeaderProps {
  isAuthenticated: boolean;
  leftNavBar?: ReactNode;
  rightNavBar?: ReactNode;
  sticky?: boolean;
  transparent?: boolean;
  whiteMenu?: boolean;
  menuItems: HeaderMenu[];
  testid?: string;
}

export const Header = ({
  isAuthenticated,
  leftNavBar,
  rightNavBar,
  sticky,
  transparent,
  whiteMenu,
  menuItems,
  testid,
}: HeaderProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const scrollDirection = useScrollDirection();
  const isAccountRelease = useFlagStatus("account");

  return (
    <>
      <div
        className={classNames(
          "top-0 z-20 w-full overflow-visible",
          sticky ? "sticky" : "relative"
        )}
        data-testid={testid}
      >
        <div
          className={classNames(
            "top-0 z-10 flex w-full items-center justify-between px-5 py-4 drop-shadow transition-all duration-300 lg:px-6 lg:py-1",
            transparent ? "bg-transparent" : "bg-white",
            scrollDirection ? null : "!bg-white"
          )}
          data-testid={testid + "__container"}
        >
          <div className={"flex items-center lg:gap-20"}>
            <AppLogo
              variant={whiteMenu && scrollDirection ? "white" : "color"}
            />

            {leftNavBar}
          </div>

          {isAccountRelease ? rightNavBar : null}
        </div>
      </div>

      <SlideOver
        origin={"left"}
        size={"small"}
        open={visible}
        onClose={() => setVisible(false)}
      >
        <SidebarMenu>
          <div className={"mt-6 grid w-full md:hidden"}>
            <ScheduleDemoButton
              phoneNumber={env.SUPPORT_PHONE_NO}
              variant={"tertiary"}
            />
          </div>
          <SidebarMenuItem
            isAuthenticated={isAuthenticated}
            menuItems={menuItems}
            onNavigate={() => setVisible(false)}
          />
        </SidebarMenu>
      </SlideOver>
    </>
  );
};

const AppLogo = ({ variant }: ComponentPropsWithoutRef<typeof Logo>) => (
  <a
    href={env.LANDING_URL}
    className={"cursor-pointer"}
    data-testid={"landing__logo__melaka"}
  >
    <Logo className={"h-7 w-full md:h-full"} variant={variant} />
  </a>
);

export default Header;
