"use client";

import Link from "next/link";
import { useRouter } from "next/router";
import { FC, useCallback, useState } from "react";

import { ChevronUpIcon } from "@saas/shared/icon";
import { Typography } from "@saas/shared/ui";
import { classNames } from "@saas/shared/utils";

type MenuItem = {
  label: string;
  url: string;
  testid?: string;
};

type MenuHeader = {
  label: string;
  testid?: string;
  url?: string;
  enabled?: boolean;
  child?: MenuItem[];
};

export interface SidebarMenuItemProps {
  isAuthenticated?: boolean;
  menuItems: MenuHeader[];
  onNavigate?: () => void;
}

export const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  menuItems,
  onNavigate: handleNavigate,
}) => {
  const [subMenu, setSubMenu] = useState<number[]>([]);
  const router = useRouter();

  const isHome = router.pathname === "/";

  /**
   * open sub-menu based on subMenu state
   */
  const sideSubMenu = useCallback(
    (menu: MenuItem[], index: number) => {
      return subMenu.includes(index + 1)
        ? menu.map((subMenu, index) => {
            return (
              <div
                className={classNames("mx-4 mt-5", index === 1 && "mb-5")}
                key={index}
              >
                <Link
                  href={subMenu.url}
                  data-testid={subMenu.testid}
                  onClick={handleNavigate}
                >
                  <span
                    className={classNames("button-small text-xs font-bold")}
                  >
                    {subMenu.label}
                  </span>
                </Link>
              </div>
            );
          })
        : null;
    },
    [subMenu, handleNavigate]
  );

  /**
   * rotate icon based on subMenu state
   */
  const subMenuIcon = useCallback(
    (index: number) => {
      return subMenu.includes(index + 1) ? "rotate-0" : "rotate-180";
    },
    [subMenu]
  );

  /**
   * render link based on menu (reload for home)
   */
  const linkHeader = useCallback((menuItem: MenuHeader) => {
    return menuItem.label === "Home" ? (
      <a
        href={menuItem.url}
        data-testid={menuItem.testid}
        onClick={handleNavigate}
      >
        <div>
          <span className={"button-small"}>{menuItem.label}</span>
        </div>
      </a>
    ) : (
      menuItem.url && (
        <Link
          href={menuItem.url}
          data-testid={menuItem.testid}
          onClick={handleNavigate}
        >
          <span className={"button-small font-sans text-xs font-bold"}>
            {menuItem.label}
          </span>
        </Link>
      )
    );
  }, []);

  const isLinkActive = (menuItem: MenuHeader) => {
    if (menuItem.label === "Home" && isHome) {
      return true;
    }
    return false;
  };

  return (
    <>
      {menuItems.map((menuItem, index) => {
        return menuItem.enabled ? (
          menuItem.url ? (
            <div
              className={classNames(
                "px-2 py-3",
                menuItem.label === "Home" && "mt-6",
                isLinkActive(menuItem) && "bg-blue-B100 rounded"
              )}
              key={index}
            >
              {linkHeader(menuItem)}
            </div>
          ) : (
            menuItem.child && (
              <div key={index}>
                <div
                  className={classNames(
                    "flex items-center justify-between px-2 py-3"
                  )}
                  data-testid={menuItem.testid}
                  onClick={() => {
                    subMenu.includes(index + 1)
                      ? setSubMenu(subMenu.filter((item) => item !== index + 1))
                      : setSubMenu([...subMenu, index + 1]);
                  }}
                >
                  <Typography
                    type={"button-small"}
                    className={"text-xs font-bold"}
                  >
                    {menuItem.label}
                  </Typography>
                  <div
                    className={classNames(
                      "transition duration-200",
                      subMenuIcon(index)
                    )}
                  >
                    <ChevronUpIcon />
                  </div>
                </div>
                {sideSubMenu(menuItem.child, index)}
              </div>
            )
          )
        ) : null;
      })}
    </>
  );
};

export default SidebarMenuItem;
